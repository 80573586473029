@import '../../../../styles/queries';

.axxes-edit-pdf-sorting {
  &-button {
    width: 110px;
    justify-content: center;
    margin-left: 5px;
  }

  &-label {
    margin: 5px 0 5px 5px;
  }

  &-column {
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: space-between;

    &-within {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.axxes-visibility-minimization {
  &-button {
    width: 45px;
    justify-content: center;
    margin-top: 3px;
  }

  &-block {
    &-no-hover {
      display: none;
    }
    &-hover {
      display: flex;
    }
  }
}

@media (max-width: $mobile-screen-max) {
  .axxes-edit-pdf-sorting {
    &-column {
      flex-direction: row;
    }
  }
}
