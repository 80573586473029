@import './variables';
@import './queries';

.axxes-cv-form {
  flex-wrap: nowrap;
  width: 100%;
  column-gap: $spacer * 2;
  row-gap: $spacer * 2;

  &__container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    @media (max-width: $form-photo-max) {
      flex-direction: column;
    }
  }

  .axxes-input__container {
    margin-bottom: $spacer;
  }

  &__photo {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    margin-bottom: $spacer;
    background-color: var(--axxes-light-background);
    object-fit: contain;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-left: $spacer;
    column-gap: $spacer;
    row-gap: $spacer;

    Button {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__inline-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: $spacer;
    margin-bottom: $spacer * 2;

    .axxes-custom-dropdown,
    .axxes-input__container {
      margin-bottom: 0;
    }

    .axxes-input__container {
      width: 100%;
    }

    Button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
    }
  }

  &__badges {
    font-size: 0.9rem;
    
    row-gap: $spacer;
    column-gap: $spacer;
    flex-wrap: wrap;

    .axxes-badge {
      padding: calc($spacer / 2)  $spacer ;
      
      & > span {
        word-wrap: break-word;
        hyphens: auto;
        max-width: 70vw;

        @media (min-width: $desktop-screen-min) {
          max-width: 40vw;
        }
        @media (max-width: $mobile-screen-max) {
          max-width: 60vw;
        }
      }
    }
  }

  &__add-block {
    display: flex;
  }

  &__dual-form {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    row-gap: $spacer;
    margin-bottom: $spacer;

    & > div {
      width: 50%;
      margin-right: $spacer * 2;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__add {
    transition: overflow 0s linear 1s, 1s ease-in-out max-height;
    transition-delay: 1s overflow;
    overflow: hidden;
    max-height: 0px;

    &.-open {
      transition: 1s ease-in-out max-height;
      max-height: 100vh;
      overflow: unset;
      animation: 0.5s delay-overflow backwards;

      @keyframes delay-overflow {
        from,
        to {
          overflow: hidden;
        }
      }
    }
  }
}

.axxes-datepicker {
  margin-bottom: 0;
}

.axxes-border-title {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0px !important;
  margin: 10px 0 20px;

  &__text {
    background: #fff;
    padding: 0 10px;
  }
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: var(--axxes-accent--dark);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: $spacer * 7;
  height: $spacer * 4.5;
  padding: 0;
  border-radius: 5px;
  background-color: var(--axxes-primary);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--axxes-accent--dark);
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--axxes-accent);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--axxes-accent--dark);
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 30px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 7px;
  display: flex;
  align-items: center;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 14px;
  height: 30px;
  align-items: center;
  display: flex;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 7px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 27px;
  height: 32px;
  border-radius: 3px;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
}

@media (max-width: $mobile-screen-max) {
  .axxes-cv-form {
    &__actions {
      flex-direction: column;
      border-top: 1px solid var(--axxes-primary);
      margin-left: 0;
      margin-bottom: $spacer * 4;
      padding-top: $spacer;

      Button {
        width: 100%;
      }
    }

    &__dual-form {
      flex-direction: column;
      & > div {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
