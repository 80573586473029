@import '../../../styles/queries';

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -25%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    max-height: 80vh;
    z-index: 1001;

    @media (max-width: $mobile-screen-max) {
        width: 95vw;
        max-width: 100%;
    }
}

.delete-modal {
    &__content {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: 55vh;

        span {
            word-wrap: break-word;
            hyphens: auto;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 30px;

        Button {
            margin-left: 15px;
        }
    }

    .nowrap {
        white-space: nowrap;
    }
}

@media (min-width: $desktop-screen-min) {
    .modal {
        max-width: 50%;
    }
}