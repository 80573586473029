.axxes-avatar__photo__small {
  width: 2rem !important;
  height: 2rem !important;
}

.axxes-avatar__photo__medium {
  width: 4rem !important;
  height: 4rem !important;
}

.axxes-avatar__photo__large {
  width: 6rem !important;
  height: 6rem !important;
}
