@import '../../styles/variables';
@import '../../styles/queries';

.axxes-generate-container {
  margin-bottom: -6.25rem;
}

.axxes-generate {
  &__container {
    position: relative;
    justify-content: center;
    align-items: center;

    & > img {
      display: none;
      width: 80%;
      margin-bottom: 10%;
    }
  }

  &__ready-text {
    display: none;
  }

  &__action-container {
    display: flex;
    flex-direction: column;
    gap: $spacer;
    position: absolute;
    top: 65px;
    right: $spacer;

    > .axxes-button__container, a {
      width: 100%;
    }

    Button {
      width: 100%;
      justify-content: center;

      & > div {
        align-items: center;
      }

      svg {
        margin-right: $spacer;
      }
    }
  }

  &__text {
    font-size: larger;

    &.-loading {
      &::after {
        content: '_';
        color: var(--axxes-accent);
        font-size: 1.5em;
        animation: 1s blink step-end infinite;
      }
    }

    &.-ready {
      display: none;
      margin-bottom: 20%;
    }
  }
}

@media (max-width: $mobile-screen-max) {
  .axxes-generate {
    &__container {
      & > img {
        display: flex;
      }
    }

    &__text {
      &.-ready {
        display: flex;
      }
    }

    &__action-container {
      position: static;
      align-items: center;
      gap: $spacer * 3;
      width: 100%;

      > .axxes-button__container, a {
        width: 80%;
      }
    }

    &__viewer {
      display: none;
    }
  }
}

@keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
