@import '../styles/queries';

.custom-footer {
  max-height: 4rem;
}

@media (max-width: $mobile-screen-max) {
  .axxes-header {
    display: none;
  }

  .custom-footer {
    display: none;
  }
}