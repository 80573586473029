@import "../../styles/mixins";
@import "../../styles/variables";

.axxes-manage {
  &__users {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: row;
    row-gap: $spacer * 4;
  }

  &__header {
    display: flex;
    flex-direction: row;
    row-gap: $spacer * 4;
    padding: $spacer;

    &__list-items {
      margin-left: auto;
    }
  }

}
