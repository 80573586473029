.axxes-edit-pdf{
  &-block-width {
    width: 50%;
  }
  &-invisible {
    background: var(--axxes-grey) !important;
    color: white;

    h1, h2, h3, h4, h5, h6 {
      color: white;
    }
  }

  &__minimized {
    color: var(--axxes-accent);
  }
}
