@import '../../../styles/queries';

.axxes-mobile-header {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    background-color: white;
    box-shadow: 0 -1px 25px 0 rgba(0,0,0,0.3);

    a {
        font-size: 1.2em;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        min-height: 50px;
    }

    i {
        color: var(--axxes-primary);
    }
    
    .axxes-icon-home {
        font-size: 1em;
    }

    .axxes-icon-users {
        font-size: 0.8em;
    }
}

@media (max-width: $mobile-screen-max) {
    .axxes-mobile-header {
      display: flex;
    }
}