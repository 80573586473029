* {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
}

.axxes-print__container {
    table {
        width: 100%;
    }
}

.hideOnPrint {
    @media print {
        display: none;
    }
}


.axxes-card__disableOnPrint {
    background-color: var(--axxes-white);
    border-top: 2px solid var(--axxes-accent);
    padding: 8px * 3;
    border-radius: 0 0 3px 3px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);

    &.--has-action {
        cursor: pointer;
    }

    &.--no-border {
        border-top: none;
    }

    @media print {
        background-color: unset;
        border-top: unset;
        padding: unset;
        border-radius: unset;
        box-shadow: unset;

        >.axxes-card__header {
            display: none;
        }

        .axxes-project-view {
            background-color: var(--axxes-white);
            border-top: 2px solid var(--axxes-accent);
            padding: 8px * 3 !important;
            border-radius: 0 0 3px 3px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);

            &.--has-action {
                cursor: pointer;
            }

            &.--no-border {
                border-top: none;
            }
        }
    }
}

.axxes-print {

    &__header,
    &__footer {
        display: none;
    }

    &__container {
        height: 100%;
        display: flex;
        flex-grow: 1;
    }
}

.axxes-project-view__print-title {
    display: none;
}

@media print {
    .axxes-root-container {
        min-height: unset;
    }

    .axxes-project-view {
        flex-direction: column;
        break-before: always;
    }

    .axxes-timeline {
        display: none;
    }

    .axxes-project-view__header {
        .axxes-avatar__photo {
            height: 50px;
            width: 50px;
        }
    }

    .axxes-project-view__content {
        margin-top: 16px;
        margin-left: 0;
    }

    .axxes-project-view__print-title {
        display: block;
    }

    .axxes-skill__skill {
        width: 125px;
        margin-right: 0px;
    }

    .axxes-skills-container__edit {
        width: 80%;
    }

    .axxes-skills-container__edit {
        flex: 0 1 50%;
    }

    .axxes-skill__level {
        width: 175px;
    }

    html,
    body {
        background: none;
        font-size: 10pt;
    }

    .axxes-user__detail {
        padding: 0 !important;
    }

    .axxes-header,
    .axxes-header__title,
    .axxes-footer,
    .axxes-button,
    .axxes-button--ghost,
    .axxes-button--subtle {
        display: none;
    }

    .axxes-card,
    .axxes-project-view {
        page-break-inside: avoid;
        page-break-inside: avoid;
    }

    .axxes-user__detail {
        display: block !important;
        width: 99%;

        >div {
            margin-bottom: 32px;
        }
    }

    .axxes-project>div:nth-child(1) {
        flex-basis: auto;
    }

    .axxes-project>div:nth-child(2) {
        flex-basis: 10%;
    }

    .axxes-project>div:nth-child(3) {
        flex-basis: 65%;
    }

    .axxes-print {

        &__header,
        &__header-space {
            height: 50px;
            display: block;
        }

        &__footer,
        &__footer-space {
            height: 30px;
            display: block;
        }

        &__header {
            position: fixed;
            top: 0;
        }

        &__footer {
            position: fixed;
            display: grid;
            grid-template-columns: auto auto auto;
            text-align: center;
            width: 100%;
            bottom: 0;
            line-height: 25px;
        }

        &__line {
            color: #E9531D;
            font-size: 40px;
            line-height: 0px;
        }
    }
}