@import '../../../styles/variables';
@import '../../../styles/queries';

$icon-width: 50px;

.axxes-link {
  flex: 0 0 50%;

  &__text {
    margin-top: 3px;
    line-height: 20px;
    max-width: calc(40vw - #{$icon-width});
  }

  &__name {
    font-weight: 500;
    word-wrap: break-word;
    word-break: break-all;
    hyphens: auto;
  }

  &__icon {
    width: $icon-width;
    font-size: 30pt;
    margin-right: 8px;
  }

  &__link {
    font-weight: 300;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: $spacer;
    row-gap: $spacer;
  }

  &__form {
    display: flex;
    flex-direction: row;
    gap: $spacer;

    &-icon {
      > div:first-of-type {
        flex-basis: 40%;
      }
    }

    > div {
      margin-right: calc($spacer / 2);
    }

    > div:last-of-type {
      margin-right: 0;
    }
  }

  > div {
    margin-bottom: calc($spacer / 2);
  }

  > div:last-of-type {
    margin-bottom: 0;
  }
}

.axxes-links {
  flex-wrap: wrap;
}

@media (max-width: $mobile-screen-max) {
  .axxes-link {
    flex-basis: 100%;

    &__form {
      flex-direction: column;
    }

    &__text {
      max-width: calc(70vw - #{$icon-width});
    }
  }
}
