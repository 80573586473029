@import '../../../styles/mixins';

.axxes-notfound {
    &__container {
      @include page-content;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 3rem;
      }
    }
  }