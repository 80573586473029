@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/queries';

.axxes-user {
  &__detail {
    @include page-content;
    display: flex;
    flex-direction: row;
    row-gap: $spacer * 4;
  }
}

.axxes-card-actions {
    width: 100%;
    margin-top: $spacer;
    display: flex;
    justify-content: flex-end;
    gap: $spacer;
}

.axxes-avatar {
  position: relative;
  flex-wrap: nowrap;
  margin-bottom: 1rem;

  &__photo {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: contain;
    object-position: center;
    background-color: var(--axxes-light-background);

    .react-loading-skeleton {
      width: 100px !important;
      height: 100px !important;
    }

    @media (max-width: $mobile-screen-max) {
      width: 75px;
      height: 75px;
    
      .react-loading-skeleton {
        width: 75px !important;
        height: 75px !important;
      }
    }
  }

  &__container {
    padding-left: 20px !important;
  }

  &__title {
    margin-bottom: 5px;
    line-height: 1.5rem;
    word-break: break-word;
    hyphens: auto;
  }

  &__text {
    font-size: 0.9rem;
    font-weight: 300;
  }

  &__text > h1,
  &__text > h2,
  &__text > h3,
  &__text > h4,
  &__text > h5,
  &__text > h6 {
    line-height: 1.5rem;
  }
}

.axxes-timeline {
  flex-grow: unset !important;
  flex-grow: 1;
}

.axxes-project {
  >div:nth-child(1) {
    flex-basis: 25%;
  }

  >div:nth-child(2) {
    flex-basis: 10%;
  }

  >div:nth-child(3) {
    flex-basis: 65%;
  }
}

.axxes-school {
  transition: 1s ease-in-out;
  overflow: hidden;
  max-height: 0px;

  &.-open {
    max-height: 100vh;
  }
}

.axxes-edit__education {
  .axxes-custom-dropdown {
    margin-bottom: 1rem;
  }
}

.axxes-cv-element-list {
  row-gap: $spacer * 2;
}

.axxes-foldable-card{
  &__header {
    align-items: center;
    cursor: pointer;
    padding: $spacer;
    margin: $spacer * -1;

    &:hover {
      background-color: var(--axxes-light-background);
    }

    h3 {
      margin-bottom: 0;
    }
  }

  &__content {
    margin-top: 1rem;
  }
}

@media (max-width: $mobile-screen-max) {
  .axxes-card-actions {
    flex-direction: column;
    
    Button {
      width: 100%;

      .axxes-flex-container {
        width: 100%;
        justify-content: center;
      }
    }
  }
}