@import './queries';

$multiplier: 0.85;

@media (max-width: $mobile-screen-max) {
  h1 {
    font-size: calc(1.375rem * #{$multiplier});
  }

  h2 {
    font-size: calc(1.25rem * #{$multiplier});
  }

  h3 {
    font-size: calc(1.1875rem * #{$multiplier});
  }

  h4 {
    font-size: calc(1.125rem * #{$multiplier});
  }

  h5 {
    font-size: calc(1rem * #{$multiplier});
  }

  body, p, span, button, a {
    font-size: calc(1rem * #{$multiplier}) !important;
  }

  .axxes-cv-form__badges, .axxes-avatar__text {
    font-size: calc(0.9rem * #{$multiplier});
  }
}
