@import '../../styles/queries';
@import '../../styles/variables';

.axxes-edit-pdf {
  display: flex;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;

  &-action {
    display: flex;
    position: sticky;
    top: 0;
    margin-top: 5px;
    z-index: 9999;
    background-color: var(--axxes-light-background);
    gap: $spacer;
    justify-content: center;

    Button {
      margin-bottom: 4px;
      width: 170px;
      justify-content: center;
      margin: $spacer 0;

      .axxes-flex-container {
        align-items: center;
      }

      span {
        margin-left: $spacer;
      }
    }
  }

  &-pdf-block {
    margin-bottom: 20px;
    margin-top: 20px;

    &-title {
      cursor: pointer;
      padding: 10px;

      &:hover {
        background-color: var(--axxes-light-background);
      }
    }
  }

  &-selected {
    display: flex;
    flex-direction: row;
    border-left: 10px solid var(--axxes-accent);
    border-radius: 3px;

    &-card {
      flex-grow: 1;
    }
  }
}

.axxes-edit-pdf-separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--axxes-grey--light);
}

.axxes-edit-pdf-separator::before,
.axxes-edit-pdf-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px dashed var(--axxes-grey--lighter);
}

.axxes-edit-pdf-separator:not(:empty)::before {
  margin-right: .25em;
}

.axxes-edit-pdf-separator:not(:empty)::after {
  margin-left: .25em;
}

@media (max-width: $mobile-screen-max) {
  .axxes-edit-pdf {
    margin-top: 60px;

    &-action {
      position: fixed;
      margin-top: 0;
      top: 0;
      left: 0;
      gap: 0;
      background-color: white;
      box-shadow: 0 10px 25px 0 rgba(0,0,0,0.3);

      Button {
        width: calc((100vw - 4 * #{$spacer}) / 3);
        margin-right: $spacer;

        & span {
          display: none;
        }
      }

      &:first-child {
        padding-left: $spacer !important;
      }
    }
  }
}

@media (min-width: $desktop-screen-min) {
  .axxes-edit-pdf {
    width: 70%;
    margin-left: 15%;
  }
}

@media (max-width: $mobile-screen-max) {
  .axxes-edit-pdf {
    &-selected {
      flex-direction: column-reverse;
    }
  }
}