@import '../../../styles/mixins';
@import '../../../styles/variables';

$photo-width: $spacer * 24;
$photo-radius: $spacer * 0.375;
$photo-indent: $spacer * 2;

$card-title-padding: $spacer * 3;

$axx-card-padding: $spacer * 3;
$card-content-lineheight: $spacer * 2;

.axxes-about {
  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: $spacer * 3;
  }

  &__photo {
    z-index: 1;
    width: $photo-width;
    min-height: $photo-width;
    border-radius: $photo-radius;
    margin-left: $photo-indent;
  }

  &__header {
    margin-top: $photo-width * -0.7;
  }

  &__header-text {
    display: flex;
    flex: 1;
    padding-left: $spacer * 3;
    flex-direction: column;
    height: $photo-width;
    justify-content: space-between;
  }

  &__title {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    max-height: 50%;
    justify-content: space-around;
  }

  &__name {
    margin-bottom: 0;
    margin-top: $spacer * 2;
    line-height: 2rem;
  }

  &__job {
    text-transform: capitalize;
  }

  &__card {
    padding-top: $photo-width * 0.7;
    margin-top: -20px;
  }

  &__card-info {
    flex: 0.35;
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: $spacer;
    row-gap: $spacer;

    &__item {
      svg {
        width: $spacer * 3;
        margin-right:  $spacer;
      }
    }

    &--city {
      font-weight: 700;
    }
  }

  &__card-content {
    padding-top: ($photo-width * 0.225) - $axx-card-padding;

    >div {
      position: relative;
    }

    &__general {
      margin-top: $spacer;
      row-gap: $spacer;
    }
  }

  &__actions {
    margin-top: $spacer;
  }

  &__options {
    @media print {
      display: none;
    }
  }

}

.axxes-location {
  &__input {
    margin-bottom: $spacer;
  }
}

@media (max-width: $mobile-screen-max) {
  .axxes-about {
    &__header {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &__photo {
      border-radius: calc($photo-width / 2);
    }

    &__header-text {
      margin-top: $spacer * 2;
      padding-left: 0;
      align-items: center;
    }

    &__card-info {
      margin-top: $spacer * 3;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: $spacer * 2;
      justify-content: center;
    }
  }
}
