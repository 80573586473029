@import './queries';

@mixin page-content {
  width: 100%;
  margin: 0 auto;

  /* Desktops and laptops */
  @media (min-width: $desktop-screen-min) {
    width: 80%;
    margin: 0 auto;
  }
}