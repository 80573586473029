@import '../../../styles/variables';
@import '../../../styles/queries';

.axxes-languages {
  gap: $spacer;
}
.axxes-language {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-direction: row;
  gap: $spacer;

  > div:first-of-type {
    flex-basis: 100%;
    justify-content: flex-start;
  }

  > div {
    display: flex;
    flex-basis: 70%;
    justify-content: flex-end;
  }

  &--edit {
    align-items: flex-start;

    Input {
      flex: 1;
    }
  }

  &__title {
    background-color: transparent !important;
    > span {
      text-align: center;
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  &__tag-container {
    gap: $spacer * 2;
    align-items: center;

    svg {
      display: none;
      width: 30px !important;
      max-height: 40px;
      color: var(--axxes-primary);
    }
  }

  &__error {
    white-space: nowrap;
    max-width: 0;
  }

  &__tag {
    border-radius: 5px;
    background-color: var(--axxes-grey);
    max-width: 6.25rem * 2;
    justify-content: center;
    align-items: center;

    &-text {
      color: white;
      padding: $spacer;
      text-transform: lowercase;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    &.-basic {
      background-color: var(--axxes-grey--light);
    }

    &.-intermediate {
      background-color: var(--axxes-grey);
    }

    &.-fluent {
      background-color: var(--axxes-primary);
    }

    &.-native {
      background-color: var(--axxes-accent);
    }
  }

  &__form {
    display: flex;
    flex-shrink: 1;
    max-width: 6.25rem * 2;
  }

  &__actions {
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: $mobile-screen-max) {
  .axxes-language {
    flex-direction: column;

    &__title-container {
      width: 100%;
      border-bottom: 1px solid var(--axxes-primary);
      margin-bottom: $spacer * 0.5;
      margin-top: $spacer;
    }
    
    & > div {
      justify-content: center !important;
    }

    &__header {
      display: none;
    }

    &__tag-container {
      width: 100%;
      & > svg {
        display: block;
      }
    }

    &__tag {
      flex: 1;
    }

    &__form {
      width: 100%;
      max-width: 100%;
    }

    &--edit > div {
      width: 100%;
    }

    &__title {
      font-size: 1.2rem;
    }
  }

  .axxes-languages {
    gap: $spacer * 3;
  }
}
