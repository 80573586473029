@import '../../../styles/queries';

.axxes-cv-element {
    &__description {
        display: flex;

        &--mobile {
            display: none;
        }

        @media (max-width: $mobile-screen-max) {
            display: none;

            &--mobile {
                display: flex;
            }
        }
    }

    .axxes-avatar {
        margin-bottom: 0;
    }

    .axxes-textarea {
        margin-bottom: 0;

        p {
            margin-bottom: 0;
        }
    }
}