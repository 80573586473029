@import "./../../../styles/variables";
@import '../../../styles/queries';

.react-date-picker__calendar {
  z-index: 99;
}

.axxes-datepicker {
  position: relative;
  width: 100%;
  margin-bottom: $spacer;
  border-radius: 0 5px 5px 0;

  label {
    top: 0;
    left: 0;
    z-index: 1000;
    position: absolute;
    display: flex;
    align-self: center;
    justify-content: center;
    position: absolute;
    pointer-events: none;
    z-index: 1;
    position: absolute;
    top: 12px;
    left: 18px;
    font-size: 1rem;
    font-weight: 300;
    text-transform: capitalize;
    margin: 0;
    line-height: 1.5rem;
    color: var(--axxes-grey);
    transition: 0.2s ease-in;
  }

  &:focus-within {
    overflow: visible;

    .axxes-datepicker__warning {
      transition: 0s ease-in-out;
      visibility: hidden;
    }
  }

  .label--float,
  &:focus-within label {
    top: 4px;
    font-size: 8px;
    line-height: 0.8rem;
    font-weight: 600;
  }

  &:focus-within label {
    color: var(--axxes-accent);
  }

  &__control {
    width: 100%;

    .react-date-picker__inputGroup__input:invalid {
      background-color: transparent;
    }

    .react-date-picker__inputGroup__divider {
      visibility: hidden;
    }

    input::placeholder {
      color: transparent;
    }

    &:focus-within,
    &--filled {
      .react-date-picker__inputGroup__divider {
        visibility: visible;
      }
      input::placeholder {
        color: grey;
      }
    }

    

    .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
    .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
      stroke: #e9531d;
    }
    .react-calendar__tile {
      &--active {
        background: #e9531d;

        &:enabled:hover {
          background: #fae1d6;
        }
      }

      &:disabled {
        color: grey;
      }

      &--now {
        background: #90b6bb;

        &:enabled:hover {
          background: #e6e6e6;
        }

        &:enabled:focus {
          background: #e6e6e6;
        }
      }
    }

    .react-date-picker {
      &__wrapper {
        cursor: pointer;
        background-color: var(--axxes-light-background);
        border: transparent;
        border-left: 2px solid var(--axxes-grey);
        height: 48px;
        padding-right: $spacer;

        &:focus,
        &:active,
        &:hover {
          border-left: 2px solid var(--axxes-accent);
        }
      }

      &__inputGroup {
        line-height: normal;
        padding: 1rem;

        &__input {
          height: unset;
          line-height: normal;
        }
      }
    }
  }

  &--error {
    .react-date-picker__wrapper {
      border-color: red;
    }
    .axxes-datepicker__warning {
      right: 0;
    }
  }

  &__warning {
    overflow: hidden;
    pointer-events: none;
    z-index: 1;
    position: absolute;
    font-weight: 300;
    text-transform: capitalize;
    transition: 0.3s ease-in-out;
    background-color: red;
    color: white;
    width: auto;
    width: 30px;
    right: -40px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;

    &:before {
      content: "⚠";
    }
  }
}

@media (max-width: $mobile-screen-max) {
  .react-date-picker__calendar {
    position: fixed !important;
    inset: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(255, 255, 255, 0.75);
  }

  .react-calendar {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    right: auto !important;
    bottom: auto !important;
    margin-right: -25% !important;
    transform: translate(-50%, -50%);
    width: 95% !important;
  }
}
