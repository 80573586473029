.axxes-edit {

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    width: 45%;
    margin: 5px 20px;
  }

  &__item-button {
    margin-right: 10px;
  }

  &__item-actions {
    margin-left: 100px;
  }

  &__search {
    width: 33%;
  }

  &__singleField {
    padding: 10px 0
  }

  &__destruction-warn {
    color: red;
  }
}