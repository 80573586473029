
.axxes-manage {
  &__edit {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 20px 0;
  }
}