@import "../../styles/_queries.scss";

.axxes-share-error {
    margin-bottom: -100px;

    &__container {
        justify-content: center;
        align-items: center;
        margin-bottom: 64px;
    }

    #subtitle {
        font-style: italic;
    }

    h1 {

        font-size: 3.5rem;
        margin-bottom: 35px;

        &::before {
            content: '_';
            color: var(--axxes-accent)
        }
    }

    p {
        margin-bottom: -5px;
        font-size: 1.2rem;
    }

    img {
        display: none;
    }
}

.custom-cookie-banner {
    z-index: 50;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: calc(100% - 40px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

@media (min-width: $desktop-screen-min) {
    .axxes-share-error {
        margin-left: 15%;

        &__container {
            align-items: flex-start;
        }
    
        img {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 70%;
        }
    }

    .custom-cookie-banner {
        max-width: calc(50vw - 20px);
    }
}