@import "./../../../styles/variables";
@import './../../../styles/queries';

.axxes-textarea {
  position: relative;
  width: 100%;
  margin-bottom: $spacer;
  overflow: hidden;
  border-radius: 0 5px 5px 0;

  &__editable > p {
    word-break: break-word;
    hyphens: auto;
    max-width: 85vw;

    @media (max-width: $mobile-screen-max) {
      max-width: 75vw;
    }
  }

  &__button {
    padding: 8px;
    margin: 0;
    font-size: 14px;
    color: grey;
    border: none;
    background: none;

    &:hover {
      background: none;
    }

    &--active {
      color: #333;
    }
  }

  &__counter {
    padding: 8px;
    margin: 0;
    font-size: 14px;
    color: grey;
    border: none;
    background: none;

    &--warning {
      color: red;
    }
  }

  &__toolbar {
    position: absolute;
    right: 5px;
    top: 0px;
  }

  label {
    display: none;
    top: 0;
    left: 0;
    z-index: 1000;
    position: absolute;
    align-self: center;
    justify-content: center;
    position: absolute;
    pointer-events: none;
    z-index: 1;
    position: absolute;
    top: 12px;
    left: 16px;
    font-size: 1rem;
    font-weight: 300;
    margin: 0;
    line-height: 1.5rem;
    color: var(--axxes-grey);
    transition: 0.2s ease-in;
  }

  .label--float,
  &:focus-within label {
    top: 4px;
    font-size: 8px;
    line-height: 0.8rem;
    font-weight: 600;
  }

  &:focus-within label {
    color: var(--axxes-accent);
  }

  &__label + input {
    padding-top: 8px;
  }

  &__warning {
    overflow: hidden;
    pointer-events: none;
    z-index: 1;
    position: absolute;
    font-weight: 300;
    text-transform: capitalize;
    transition: 0.3s ease-in-out;
    background-color: red;
    color: white;
    width: auto;
    width: 30px;
    right: -40px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;

    &:before {
      content: "⚠";
    }
  }

  &:not(.axxes-textarea--readonly) {
    cursor: text;
    background-color: var(--axxes-light-background);
    border: transparent;
    border-left: 2px solid var(--axxes-grey);

    div:not(.axxes-textarea__toolbar):not(.axxes-textarea__warning) {
      line-height: normal;
      padding-left: 16px;
      padding-right: 8px;
      padding-top: 16px;
      padding-bottom: 8px;
    }

    &:focus,
    &:active,
    &:hover {
      border-left: 2px solid var(--axxes-accent);
    }

    label {
      display: flex;
    }
  }

  
}

.axxes-textarea__readonly-placeholder {
  display: flex !important;
}

.axxes-textarea--error {
  border-color: red;
  right: 0;

  .axxes-textarea__warning {
    right: 0;
  }
}
