@import '../../../styles/variables';
@import '../../../styles/queries';

.axxes-details-header {
  background-color: white;
  margin-bottom: -20px;
  gap: $spacer;

  &__consultant {
    width: 100%;
    z-index: 2;
    position: relative;
    top: 120px;
    justify-content: flex-end;
    margin-top: -100px;

    @media (max-width: $mobile-screen-max) {
      top: 95px;
    }
  }

  &__left-column {
    gap: $spacer;
  }

  &__container {
    flex-wrap: wrap-reverse;
    gap: $spacer;
    align-items: flex-end;
  }

  &__row {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: $spacer;
  }

  &__version-container {
    align-items: center;
    gap: $spacer;
  }

  &__recipient-container {
    align-items: center;
    gap: $spacer;
  }

  &__input {
    width: 200px;
  }

  &__button {
    white-space: nowrap;
    justify-content: center;
  }

  &__error {
    margin-top: 5px;
    margin-bottom: -3px;
    color: red;
  }

  &__pdf-container {
    flex: 1 0;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    max-height: 48px;
  }

  &__sync {
    align-items: center;
    margin-right: $spacer * 2;
    cursor: pointer;

    label {
      margin-right: $spacer;
      text-align: end;
      white-space: nowrap;
    }

    &-toggle {
      cursor: pointer;
      color: #f2f5f5;
      font-size: 0.8rem;
    }
  }

  &__share {
    margin-top: 15px;
    align-items: center;
    max-width: 90vw;

    &-close {
      cursor: pointer;
      margin-right: 8px;
    }

    &-link {
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-copy {
      margin-left: 10px;
    }
  }

  @media (max-width: $mobile-screen-max) {
    &__version-container {
      flex-wrap: wrap;
      flex-grow: 1;
    }
     
    &__recipient-container {
      flex-wrap: wrap;
    }

    &__input {
      flex-grow: 1;
    }

    &__full-width {
      width: 100%;

      .axxes-button__container {
        width: 100%;
      }

      Button {
        width: 100%;
      }
    }

    &__share {
      max-width: 83vw;
    }
    
  }

}
