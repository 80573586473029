@import './styles/edit';
@import './styles/print';
@import './styles/cv-form';
@import './styles/fonts';

.axxes-root__wrapper {
  margin-bottom: 6.25rem;
}

.axxes-footer {
  z-index: 1;
}
