@import '../../styles/mixins';
@import '../../styles/variables';

.axxes-manage {
    &-users {
        &__grid {
            background: white;
        }

        &__item {
            display: grid;
            grid-template-columns: 0.5fr 2fr 2fr 2fr 1fr;
            grid-template-rows: auto;
            padding: $spacer;
            cursor: pointer;
            border-bottom: 1px solid #F2F5F5;
            color: #313D47 !important;

            &:hover {
                transition: 0.2s;
                background-color: #F2F5F5; 
            }

            div {
                display: flex;
                align-items: center;
            }
        }

        &__picture img {
            border-radius: 50%;
            height: 40px;
            width: 40px;
            background-color: lightgrey;
        }

        &__name {
            font-weight: bold;
        }

        &__role {
            text-transform: capitalize;
        }
    }
}