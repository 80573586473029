@import '../../../styles/variables';
@import '../../../styles/queries';

.axxes-projects-list {
  &__sortable-item {
    &:last-child {
      .axxes-project-view__content {
        margin-bottom: $spacer;
      } 
    }

    &:not(.--draggable) + &:last-child,
    &:only-child {
      .axxes-projects-list__item__overlay {
        margin-bottom: 0;
      }
    }

    &.--draggable {
      touch-action: none;
      cursor: grab;
    }
  }

  &__drag-overlay {
    cursor: grabbing;
  }

  &__item {
    display: flex;
    flex-direction: row;

    &.--active {
      opacity: 0.5;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      margin-right: $spacer * 2;
      gap: $spacer;
      justify-content: center;
      align-items: center;
      height: 100px;
      flex: 0 0 50px;
    }

    &__fill-div {
      display: flex;
      flex: 0 0 50px;
      margin-right: $spacer * 2;
      justify-content: center;
      align-items: center;

      svg {
        color: var(--axxes-grey--lighter)
      }
    }

    &__overlay {
      width: 100%;
      margin-bottom: $spacer * 2;
    }
    
    &__toggle {
      display: none;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: $spacer;
      flex: 1;
    }
  }
}

.axxes-project-view {
  &__container {
    row-gap: $spacer * 3;
  }

  &__header {
    flex: 10% 0;

    .axxes-avatar {
      margin-bottom: 0;
    }
  }

  &__tasks {
    padding-left: 0 !important;
    li {    
      padding-left: 10px;
      margin-bottom: $spacer;
      max-width: 76vw;
      word-wrap: break-word;
      hyphens: auto;
    }
  }

  &__content {
    flex: 100% 0;
    margin-bottom: $spacer * 3;

    p {
      margin-top: 0;
    }
  }
}

.axxes-project-edit {
  gap: 0 !important;

  .axxes-cv-form__photo {
    margin-right: $spacer * 2;
  }
}

.task-item {
  padding: 0.5rem;
  font-size: 13pt;
  background-color: var(--axxes-light-background);
  margin: 2px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  touch-action: none;

  &__drag-icon {
    display: flex;
    margin-right: 18px;
    margin-left: 10px;
    color: var(--axxes-grey--lighter);
    align-items: center;
  }

  &__draggable {
    &:hover {
      cursor: grab;
    }
  }

  &__not-draggable {
    &:hover {
      cursor: not-allowed;
    } 
  }

  #task {
    word-wrap: break-word;
    hyphens: auto;
    width: 100%;
    max-width: 51vw;
  }
}

.axxes-edit-form {
  width: 100%;
  align-items: center;
  padding: $spacer 0 !important;
  flex-direction: column !important;
  gap: $spacer;

  &__input {
    padding-top: 10px;
    width: 100%;
    
    .axxes-input__container {
      margin-bottom: 0 !important;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: $spacer;
    margin-right: $spacer;
    column-gap: $spacer;
    row-gap: $spacer;

    Button {
      height: $spacer * 4.5;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__description {
    margin-bottom: $spacer;
  }
}

.axxes-timeline{
  margin-right: $spacer * 2;
  &::before {
    margin-top: $spacer * 5 !important;
  }
}

@media (min-width: $desktop-screen-min) {
  .axxes-edit-form {
    flex-direction: row !important;

    &__input {
      width: 80%;
    }

    &__actions {
      width: 20%;
    }
  }
}

@media (max-width: $mobile-screen-max) {
  .axxes-projects-list {
    &__item {
      flex-direction: column;
      border-top: 1px solid var(--axxes-primary);

      &__actions {
        flex-direction: row;
        // justify-content: space-between;
        margin-top: $spacer;
        margin-right: 0;
        height: auto;
        flex: auto;
      }

      &__fill-div {
        display: none;
      }

      &__toggle {
        display: flex;
      }
    }
  }

  .axxes-project-view {
    &__container {
      margin-top: $spacer * 2;
    }

    &__content {
      margin-bottom: 0;
    }
  }

  .axxes-project-edit {
    margin-top: $spacer * 2;
  }

  .axxes-timeline {
    display: none;
  }
}
