@import '../../../styles/variables';
@import '../../../styles/queries';

.axxes-skill-edit {
  width: 100%;
  align-items: flex-end;
  padding: $spacer 0 !important;
  flex-direction: column !important;
  column-gap: $spacer * 2;
  row-gap: $spacer * 2;

  &__add {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: $spacer;
  }

  &__skill {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__level {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: $spacer;
    margin-right: $spacer;
    column-gap: $spacer;
    row-gap: $spacer;

    Button {
      height: $spacer * 4.5;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.axxes-skills-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  &__title {
    display: flex;
    flex-direction: row;
    column-gap: $spacer;
    align-items: center;

    Button {
      padding: 4px;
    }
  }

  &__edit {
    display: flex;
    align-items: center;
    flex: 0 100%;
    box-sizing: border-box;
    flex-direction: column;
    width: 94%;

    &--active {
      width: 100%;
    }
  }

  &__new {
    width: 100%;
    margin-left: auto;
  }

  .edit-overlay {
    width: 100%;
  }

  .axxes-progress .axxes-progress__empty,
  .axxes-progress .axxes-progress__filled {
    width: $spacer * 5;
    max-width: $spacer * 5;
  }
}

.axxes-skill {
  display: flex;
  padding: $spacer 0 !important;
  align-items: center;
  

  &-active {
    opacity: 0.5;
  }

  &--dragging {
    cursor: grabbing;
  }

  &__skill {
    margin-right: $spacer * 4;
    width: 40%;
    max-width: 40vw;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__level {
    width: 60%;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__sortable-item {
    width: 100%;

    &:hover {
      cursor: grab;
    }

    &.--draggable {
      touch-action: none;
    }
  }

  &__drag-handle {
    display: flex;
    width: 6%;
    color: var(--axxes-grey--lighter);
    align-items: center;
    justify-content: center;
  }
}

.share-view {
  width: 100%;
}

.axxes-skill-levels-table {
  & > div {
    padding-bottom: $spacer * 2;
    vertical-align: top;
    display: flex;
    flex-direction: row;
  }

  .level{
    white-space: nowrap;
    font-weight: 500;
    padding-right: $spacer * 2;
    min-width: 120px;

    &::before {
      content: '- ';
      color: var(--axxes-accent);
    }
  }
}

@media (min-width: $desktop-screen-min) {
  .axxes-skill-edit {
    flex-direction: row !important;
    align-items: center;
  }

  .axxes-skills-container {
    &__edit {
      box-sizing: border-box;
      flex-direction: row !important;
    }

    &__share {
      flex: 0 50%;
    }

    &__new {
      width: 100%;
      margin-left: auto;
    }
  }
  .axxes-skill {
    &__sortable-item {
      width: 50%;
    }
  }
}

@media (max-width: $mobile-screen-max) {
  .axxes-progress {
    div {
      max-width: 20px !important;
    }
  }

  .axxes-skill {
    &__skill {
      width: 60%;
    }

    &__level {
      width: 40%;
    }
  }

  .axxes-skill-levels-table > div {
    flex-direction: column;
    gap: $spacer;
  }

  .axxes-skill-edit__add {
    flex-direction: column;
  }
}
