@import '../../../../../styles/queries';

.axxes-sortable-item{
  touch-action: none;
  padding: 10px 10px 10px 10px;
  margin: 20px 0;
  border: white 2px solid;
  border-radius: 20px;
  display: flex;
  flex-direction: row;

  &:hover {
    cursor: grab;
    background-color: var(--axxes-light-background);
  }

  &.--active {
    opacity: 0.5;
  }

  &.--dragging {
    &:hover {
      cursor: grabbing;
      background-color: none;
    }
  }

  &__drag-handle {
    display: flex;
    width: 20px;
    color: var(--axxes-grey--lighter);
    align-items: center;
  }

  &__children-container {
    width: 98%;
  }

  @media (max-width: $mobile-screen-max) {
    border: 1px solid var(--axxes-primary);
  }
}