@import '../../styles/queries';

.axxes-loading {

  &__container {
    position: relative;
    justify-content: center;
    align-items: center;
  }

  &__loading-text {
    color: #1d242b;
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  @media (max-width: $mobile-screen-max) {
    &__loading-text {
      font-size: 3rem;
    }
  }
}

