.axxes-edit-pdf-skill {
  &-container {
    max-width: 70%;
  }

  &-name {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-item {
    flex-grow: 1;
    max-width: 300px;
    min-width: 200px;
    margin-bottom: 6px;
    border-left: 2px solid var(--axxes-grey--light);
  }
  &-del {
    margin-left: auto;
    margin-right: 0;
  }
}
