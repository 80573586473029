@import '../../../../styles/variables';
@import '../../../../styles/queries';

.axxes-project-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.axxes-project-item-view {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin: 0;

  .axxes-avatar {
    width: 70%;
    margin-bottom: 0;
    padding-right: $spacer * 3 !important;
  }

  &__content {
    display: flex;
    width: 30%;
    flex-direction: column;
    gap: $spacer;
    align-items: flex-start;

    span {
      font-weight: bold;
    }
  }

  &__tasks-skills {
    display: flex;
    flex-direction: column;
    gap: $spacer;
  }

  &__actions {
    justify-content: center;
    display: flex;
    gap: $spacer;
    flex-direction: column;
  }

  &__sortable:hover {
    cursor: grab;
  }
}

@media (max-width: $mobile-screen-max) {
  .axxes-project-item-view {
    flex-direction: column;

    .axxes-avatar {
      width: 100%;
    }

    &__content {
      width: 100%;
      align-items: center;
      margin-top: $spacer;
    }

    &__tasks-skills {
      flex-direction: row;
    }

    &__actions {
      flex-direction: row;
      margin-top: $spacer;
    }
  }

  .axxes-project-item-container {
    flex-direction: column;
  }
}
