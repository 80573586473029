.edit-overlay {
  width: 100%;

  &--has-editing {
    position: relative;
    cursor: pointer;

    .edit-text {
      opacity: 0;
      cursor: pointer;
    }

    &::after {
      opacity: 0;
    }

    &:hover::after {
      opacity: 0.9;
    }

    &:hover {
      .edit-text {
        opacity: 0.9;
      }
    }
  }

  &--has-editing::after {
    transition: 0.2s ease-in-out;
    pointer-events: none;
    content: '';
    background-color: var(--axxes-light-background);
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }
}

.edit-text {
  transition: 0.2s ease-in-out;
  opacity: 0;
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  letter-spacing: 1px;
}

textarea {
  margin: 0;
  padding: 0;
}
